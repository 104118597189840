import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import Table from "@amzn/awsui-components-react/polaris/table";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import { useCollection } from "@amzn/awsui-collection-hooks";
import TableEmptyState from "../../../../common/TableEmptyState";
import TableNoMatchState from "../../../../common/TableNoMatchState";
import { fundingHistoryColumnDefinitions } from "./tableConfig";
import { filterItem } from "./util";
import { Box } from "@amzn/awsui-components-react";
import {
  ERROR_TYPE,
  hasError,
  retryApiCall,
} from "../../../../../../shared/util/services/data/DataService";
import { setErrorMessage } from "../../../../../../shared/util/common/helper";
import { getFundRequestComments } from "../../../../../util/services/data/FundRequestService";

export const FundRequestComments = forwardRef(
  ({ setNotificationsItems }, ref) => {
    const pageSize = 10;
    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);

    useEffect(async () => {
      await getComments();

      setIsLoading(false);
    }, []);

    const getComments = async () => {
      const data = [];
      let nextPageToken = "";
      while (nextPageToken != null) {
        try {
          const response = await retryApiCall({
            callApi: getFundRequestComments({ nextPageToken }),
          });

          //TODO: Add a failure condition
          if (hasError(response) && response.errorType === ERROR_TYPE.BANNER) {
            setErrorMessage({
              setNotificationsItems,
              content: response.message,
              status: response.status,
            });
            setIsLoading(false);
            break;
          }
          data.push(...response.history);
          nextPageToken = response["nextPageToken"];
        } catch (e) {
          console.error(e);
          setIsLoading(false);
          break;
        }
      }
      setHistory(data);
    };

    const { items, actions, collectionProps, filterProps, paginationProps } =
      useCollection(history || [], {
        pagination: { pageSize },
        empty: <TableEmptyState resourceName="history" />,
        filtering: {
          noMatch: (
            <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
          ),
          filteringFunction: (item, filteringText) => {
            return filterItem({ item, filteringText });
          },
        },
        sorting: {},
        selection: {},
      });

    return (
      <div ref={ref}>
        <Table
          {...collectionProps}
          loading={isLoading}
          loadingText="Loading fund request history..."
          items={items}
          columnDefinitions={fundingHistoryColumnDefinitions}
          sortingColumn={{ sortingField: "date" }}
          wrapLines={true}
          empty={
            <Box textAlign="center" color="inherit">
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No fund request history available.
              </Box>
            </Box>
          }
          filter={
            <Grid
              gridDefinition={[
                { colspan: 4 },
                { colspan: 1 },
                { colspan: 1 },
                { colspan: 1 },
                { colspan: 1 },
              ]}
            >
              <Input
                type="search"
                value={filterProps.filteringText}
                onChange={(event) => {
                  actions.setFiltering(event.detail.value);
                }}
                placeholder="Enter search criteria..."
                label="Search history"
                ariaDescribedby={null}
              />
            </Grid>
          }
          header={
            <Header counter={items.length + "/" + history.length}>
              Fund Request History
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
        />
      </div>
    );
  }
);

FundRequestComments.displayName = "FundRequestComments";

FundRequestComments.propTypes = {
  setNotificationsItems: PropTypes.func,
};
