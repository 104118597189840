export const FINANCIAL_APPROVAL_GUIDE_LINK =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W00000dyzKlQAI";
export const PROGRAM_ENGAGEMENT_NEW_ID_GUIDE_LINK =
  "https://amazon.awsapps.com/workdocs/index.html#/document/38ac9ddadbb6d024760b5810f025a67af75668db44413bcdc6b3ad2b423e0909";
export const PARTNER_FUNDING_BENEFIT_GUIDE_LINK =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?keyword=AWS+Partner+Funding+Benefits+Guide";
export const MIGRATION_ASSESSMENT_REPORT_TEMPLATE_LINK =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W0000104RklQAE";
export const MIGRATION_COMPLETION_REPORT_TEMPLATE_LINK =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?Id=0698W0000104RkgQAE";
export const MANAGED_SERVICES_AGREEMENT_TEMPLATE_LINK = "";
export const PARTNER_SCOPE_CHECKLIST_LINK =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?categories=a1o8W00000UnCZpQAN";
export const MAP_CUSTOMER_SIGNOFF_TEMPLATE =
  "https://partnercentral.awspartner.com/partnercentral2/s/resources?categories=a1o8W00000UnCkwQAF";
